const { axios } = require("../axios");

async function createUser(name, screenName, email, profileImage) {
  try {
    let response = await axios.post(
      "/users",
      {
        name: name,
        screenName: screenName,
        email: email,
        profileImage: profileImage,
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function getUserById(id) {
  try {
    let response = await axios.get(`/users/id/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function getUserByEmail(email) {
  try {
    let response = await axios.get(`/users/email/${email}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function getUsers() {
  try {
    let response = await axios.get(`/users/`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function updateUser() {}

const User = {
  createUser,
  getUserById,
  getUserByEmail,
  getUsers,
  updateUser,
};

export default User;