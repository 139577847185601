import React, { useState } from "react";
import { useRFC } from "../../utils/RFCContext";
import AddSectionComposer from "../composer/AddSectionComposer";

function AddSectionButton({ children }) {
  const { rfc } = useRFC();
  const [show, setShow] = useState(false);

  function onClickHandler(event) {
    event.stopPropagation();
    setShow(true);
  }

  function onHideHandler() {
    setShow(false);
  }

  return (
    <>
      <div onClick={onClickHandler}>
        {children}
        <AddSectionComposer show={show} handleClose={onHideHandler} rfc={rfc} />
      </div>
    </>
  );
}

export default AddSectionButton;
