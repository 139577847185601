import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Spinner } from "react-bootstrap";
import Post from "../api/Post";
import { PostContext } from "../utils/PostContext";
import { CommentCard } from "./cards/CommentCard/";

/*
 * Fetches and renders comments on post with id = {id}
 */
const CommentsRenderer = ({ id, updateWith }) => {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchComments() {
      setIsError(false);
      setIsLoading(true);
      try {
        let response = await Post.ReadComments(id);
        setComments(response);
      } catch (error) {
        console.log(error); // @TODO
        setIsError(true);
      }
      setIsLoading(false);
    }
    fetchComments();
  }, [id, updateWith]);

  return (
    <>
    {/*<div className="d-flex p-3 border-top">
        <span>
          <b>{comments.length}&nbsp;</b>
          <span className="text-steel">
            Comment{comments.length === 1 ? "" : "s"}
          </span>
        </span>
  </div>*/}
     

      {isError && <div> Something went wrong </div>}
      {isLoading ? (
        <Spinner animation="grow" variant="primary"></Spinner>
      ) : (
        <CommentList comments={comments} />
      )}
    </>
  );
};

function CommentList({ comments }) {
  return (
    <ul className="list-unstyled pl-0">
      {comments &&
        comments.map((post, index) => <Comment key={post.id} post={post} />)}
    </ul>
  );
}

function Comment({ post }) {
  const history = useHistory();
  post.mode = 3;
  post.actionbar = {
    comment: {
      icon: {
        className: "mt-2",
        viewBox: "2 2 26 26",
        strokeWidth: "1.5",
      },
    },
  };
  return (
    <li className="pcard" onClick={(e) => history.push(`/posts/${post.id}`)}>
      <PostContext.Provider value={{ post }}>
        <CommentCard />
      </PostContext.Provider>
    </li>
  );
}
export default CommentsRenderer;
