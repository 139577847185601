const { axios } = require("../axios");
const qs = require("qs");

async function CreateWithSummary(title, summary, publish) {
  try {
    let response = await axios.post("/rfcs", {
      title: title,
      summary: summary,
      publish: publish,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function Read(id) {
  try {
    let response = await axios.get(`/rfcs/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function Delete(id) {
  try {
    let response = await axios.delete(`/rfcs/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function CreateSectionAndAdd(rfcid, title, content) {
  try {
    let response = await axios.post(`/rfcs/${rfcid}/sections`, {
      title: title,
      content: content,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function DeleteSectionAndRemove(id, sectionid) {
  try {
    let response = await axios.delete(`/rfcs/${id}/sections/${sectionid}`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function MoveSectionUp(rfcid, sectionid) {
  try {
    let response = await axios.put(`/rfcs/${rfcid}/sections/${sectionid}/up`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function MoveSectionDown(rfcid, sectionid) {
  try {
    let response = await axios.put(`/rfcs/${rfcid}/sections/${sectionid}/down`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function Publish(id) {
  try {
    let response = await axios.put(`/rfcs/${id}/publish`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function Finalize(id) {
  try {
    let response = await axios.put(`/rfcs/${id}/finalize`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function Activate(id) {
  try {
    let response = await axios.put(`/rfcs/${id}/activate`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function Close(id) {
  try {
    let response = await axios.put(`/rfcs/${id}/close`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function ListPublic(authorId, states) {
  try {
    let response = await axios.get(`/rfcs/list/public`, {
      params: {
        authorId: authorId,
        states: states,
      },

      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function ListUnpublished() {
  try {
    let response = await axios.get(`/rfcs/list/unpublished`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function ListDraft() {
  try {
    let response = await axios.get(`/rfcs/list/draft`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function ListActive() {
  try {
    let response = await axios.get(`/rfcs/list/active`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function ListArchive() {
  try {
    let response = await axios.get(`/rfcs/list/archive`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function ListMyDraft() {
  try {
    let response = await axios.get(`/rfcs/list/mydraft`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

const RFC = {
  CreateWithSummary,
  Read,
  Delete,
  CreateSectionAndAdd,
  DeleteSectionAndRemove,
  MoveSectionUp,
  MoveSectionDown,
  Publish,
  Finalize,
  Activate,
  Close,
  ListPublic,
  ListUnpublished,
  ListDraft,
  ListActive,
  ListArchive,
  ListMyDraft,
};

export default RFC;