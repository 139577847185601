import React, { useState } from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import RFC from "../../../api/RFC";
import { useRFC } from "../../../utils/RFCContext";

function ActivateModal({ show, onHide }) {
  const { rfc, forceUpdate } = useRFC();
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);
  
  let closeButton = isPending ? {} : { closeButton: true };
  let disabled = isPending ? { disabled: true } : {};

  function handleClose(e) {
    e.stopPropagation();
    onHide();
  }

  async function handleActivate(e) {
    e.stopPropagation();
    setIsError(false);
    setIsPending(true);
    try {
      await RFC.Activate(rfc.id);
      setIsPending(false);
      forceUpdate();
      onHide();
    } catch (error) {
      setIsError(true);
      setIsPending(false);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-50w"
      >
        <Modal.Header className="" {...closeButton}>
          <Modal.Title>Make Active</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Collapse in={isError}>
            <div id="">
              <Alert show={isError} variant="danger">
                We encountered error while making RFC active. Please try again
              </Alert>
            </div>
          </Collapse>
          <span className="">RFC in active state is ready for action</span>
        </Modal.Body>

        <Modal.Footer>
          {isPending ? null : (
            <Button variant="secondary" size="sm" onClick={handleClose}>
              Cancel
            </Button>
          )}
          {isPending ? <Spinner animation="grow" variant="primary" /> : null}

          <Button variant="primary" size="sm" onClick={handleActivate} {...disabled}>
            {isPending ? "Activating" : "Make Active"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ActivateModal;
