import React, { useState } from "react";
import { Alert, Button, Modal, ProgressBar } from "react-bootstrap";
import RFCComposerForm from "./RFCComposerForm";

function RFCComposer({ show, handleClose }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [action, setAction] = useState("");
  const [hasTitle, setHasTitle] = useState(false);
  const [contentLength, setContentLength] = useState(false);
  const formId = "NewRFC";

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      dialogClassName="rfc-composer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal.Header className="border-bottom-0" closeButton>
        <h5>Request For Comments</h5>
      </Modal.Header>

      <ProgressBar
        now={(contentLength * 100) / 300}
        variant={contentLength >= 300 ? "warning" : ""}
        style={{ height: "2px" }}
      />

      <Modal.Body>
        <Alert show={isError} variant="danger">
          Something went wrong. Please try again
        </Alert>
        <RFCComposerForm
          formId={formId}
          action={action}
          setIsError={setIsError}
          setIsSubmitting={setIsSubmitting}
          onHide={handleClose}
          setHasTitle={setHasTitle}
          setContentLength={setContentLength}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="text-secondary mr-auto">
          {300 - contentLength ? 300 - contentLength : ""}
        </div>

        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          variant="outline-primary"
          id="save"
          onClick={() => setAction("save")}
          type="submit"
          form={formId}
          disabled={isSubmitting || !hasTitle || !contentLength}
        >
          {isSubmitting ? "Saving" : "Save For Later"}
        </Button>

        <Button
          id="publish"
          onClick={() => setAction("publish")}
          type="submit"
          form={formId}
          variant="primary"
          disabled={isSubmitting || !hasTitle || !contentLength}
        >
          {isSubmitting ? "Publishing" : "Publish"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RFCComposer;
