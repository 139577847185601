import React from "react";
import { Post } from "../../Post";
import { usePost } from "../../../utils/PostContext";
import RFCSectionCardDropDown from "./RFCSectionCardDropDown";

function RFCSectionCard({ id }) {
  const { post } = usePost();
 
  return (
    <>
      <div className={post.className} onClick={post.onClick}>
        <Post as="RFCSection" dropdown={<RFCSectionCardDropDown />} />
      </div>
    </>
  );
}

export default RFCSectionCard;
