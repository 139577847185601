import React from "react";
import { useHistory } from "react-router-dom";
import ProfilePicture from "../ProfilePicture";
import AuthorInfo from "../../components/cards/PostAuthorInfo";
import { useRFC } from "../../utils/RFCContext";
import Actionbar from "../Actionbar/";
import RFCActions from "./RFCActions";
import RFCModeActions from "./RFCModeActions";
import { modes } from "./modes";

function RFCSummary() {
  const { rfc } = useRFC();
  const history = useHistory();

  return (
    <>
      <div
        className={rfc.summary.className}
        onClick={
          rfc.summary.onClick ? () => history.push(`/posts/${rfc.id}`) : null
        }
      >
        <div className="d-flex align-items-top ">
          <div className="">
            <ProfilePicture url={rfc.author.profileImage} size="lg" />
          </div>
          <div className="d-flex flex-column px-2">
            <div className="d-flex align-items-top">
              <AuthorInfo author={rfc.author} />
            </div>
            <div className="d-flex align-items-center">
              <div className="text-sm text-steel">{rfc.state}</div>
            </div>
          </div>

          <div className="d-flex ml-auto">
            <RFCActions />
            <RFCModeActions />
          </div>
        </div>

        <div className="pt-3 text-2xl text-weight-600"> {rfc.title}</div>
        <div className="pt-2 text-2xl text-format"> {rfc.content}</div>
        {rfc.mode !== modes.edit ? (
          <div className="mt-3 mb-2 p-3 border-top border-bottom">
            <b>{rfc.comment_count}</b> Comments
          </div>
        ) : null}
        <Actionbar as="RFCSummary" size="lg" />
      </div>
    </>
  );
}

export default RFCSummary;
