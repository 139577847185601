import axios from "axios";
import config from "../config";

let getAccessTokenSilently = null;
function setGetAccessTokenSilently(f) {
  getAccessTokenSilently = f;
}

axios.defaults.baseURL = config.api.baseURL;
axios.defaults.timeout = config.api.timeout;
axios.interceptors.request.use(async (req) => {
  try {
    let accessToken = await getAccessTokenSilently({
      audience: config.auth0.audience,
      scope: "email"
    });
    req.headers.authorization = `Bearer ${accessToken}`;
  } catch (error) {
    throw error; // @TODO test this condition
  }
  await new Promise((resolve) => setTimeout(resolve, config.api.delay));
  return req;
});

export { axios, setGetAccessTokenSilently };
