import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import Post from "../../../api/Post";
import { usePost } from "../../../utils/PostContext";
import { handleApiErrors } from "../../../common/errors";

// TODO: Form input validation
function EditSectionComposerForm({
  section,
  formId,
  setIsError,
  setIsSubmitting,
  onHide,
  setHasEdits,
  setHasContent,
}) {
  const history = useHistory();
  const [title, setTitle] = useState(section.title);
  const [content, setContent] = useState(section.content);
  const { refreshSection } = usePost();
  const formCtrlContent = useRef(null);

  function autoresize(element) {
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "px";
    element.scrollTop = element.scrollHeight;
  }

  useEffect(() => {
    formCtrlContent.current.focus();
    autoresize(formCtrlContent.current); // sets initial size to the contents of the textarea
  }, [content]);

  function handleChangeTitle(event) {
    setIsError(false);
    setTitle(event.target.value); // can't rely on title at this point
    setHasEdits(true);
  }

  function handleChangeContent(event) {
    setIsError(false);
    setContent(event.target.value);
    setHasEdits(true);
    setHasContent(event.target.value.length > 0); // can't rely on "content" at this point
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsError(false);
    setIsSubmitting(true);

    try {
      section = await Post.SaveRFC(section.id, title, content);
      refreshSection();
      onHide();
    } catch (error) {
      setIsError(true);
      handleApiErrors(error, history);
    }
    setIsSubmitting(false);
  }

  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <Form.Control
          onKeyDown={(e) => e.stopPropagation()} // to prevent losing focus
          className="pt-0 form-control-title"
          as="textarea"
          rows="1"
          placeholder="Title of the section (optional)"
          onChange={handleChangeTitle}
          value={title}
        />
        <Form.Control
          ref={formCtrlContent}
          onKeyDown={(e) => e.stopPropagation()} // to prevent losing focus
          className="form-control-content white-space-pre-wrap"
          as="textarea"
          rows="4"
          role="textbox"
          placeholder="Text of the section"
          onChange={handleChangeContent}
          onInput={(e) => autoresize(e.target)}
          onLoad={(e) => {
            console.log("onLoad ..");
          }}
          value={content}
        />{" "}
      </Form>
    </>
  );
}

export default EditSectionComposerForm;
