import React from "react";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import Page401 from "../../pages/Page401";
import Page404 from "../../pages/Page404";
import Page5XX from "../../pages/Page5XX";

/*
 * 500 Internal Server Error
 * 302 Redirect 
 * 401 Authorization Error
 * 404 Not found Error
 */
function handleApiErrors(error, history) {
  // @TODO log to sentry
    history.replace(history.location.pathname, {
    errorStatusCode: error.statusCode,
  });
}

const ErrorHandler = ({ children }) => {
  const location = useLocation();
  const errorStatusCode = get(location.state, "errorStatusCode");

  console.log(location, errorStatusCode);

  if (errorStatusCode === 401) {
    return <Page401 />;
  } else if (errorStatusCode === 404) {
    return <Page404 />;
  } else if (errorStatusCode >= 500) {
    return <Page5XX errorStatusCode={errorStatusCode} />
  } else {
    return children;
  }
};

export { ErrorHandler, handleApiErrors };
