import React from "react";
import { FiChevronsDown } from "react-icons/fi";
import { usePost } from "../../utils/PostContext";
import { useRFC } from "../../utils/RFCContext";
import RFC from "../../api/RFC";

function MoveDown() {
  const { post } = usePost();
  const { rfc, dispatchMoveSectionDown } = useRFC();

  async function handleMoveDown() {
    try {
      await RFC.dispatchMoveSectionDownMoveSectionDown(rfc.id, post.id);
      dispatchMoveSectionDown(post);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={post.actionbar.movedown.icon.className}>
      <FiChevronsDown
        viewBox={post.actionbar.movedown.icon.viewBox}
        strokeWidth={post.actionbar.movedown.icon.strokeWidth}
        onClick={event => {
          event.stopPropagation();
          handleMoveDown();
        }}
      />
    </div>
  );
}

export default MoveDown;
