export default {
  app: {
    title: process.env.REACT_APP_TITLE,
  },
  api: {
    baseURL: process.env.REACT_APP_API_BASEURL,
    timeout: 2500,
    delay: 0
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: "https://api.postel.io/api/",
    onLoginRedirectUri: "/home"
  }
};
