import React from "react";
import RFCListCard from "./RFCListCard";

function RFCList({ list }) {
  return (
    <ul className="rfc-list">
      {list && list.map((rfc) => <RFCListCard key={rfc.id} rfc={rfc} />)}
    </ul>
  );
}

export default RFCList;
