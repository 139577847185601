import React from "react";
import Comment from "./Comment.js";
import Edit from "./Edit.js";
import Delete from "./Delete.js";
import MoveUp from "./MoveUp.js";
import MoveDown from "./MoveDown.js";
import { usePost } from "../../utils/PostContext.js";

function Actionbar({ as, size }) {
  const { post } = usePost();
  let width;

  if (!post.actionbar) {
    return null;
  }

  if (as === "RFCSummary") {
    width = "20%";
    
  } else {
    width = "90%";
  }

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ maxWidth: width }}
    >
      {post.actionbar.comment ? <Comment as={as} size={size} /> : null}
      {post.actionbar.edit ? <Edit as={as} size={size} /> : null}
      {post.actionbar.moveup ? <MoveUp as={as} size={size} /> : null}
      {post.actionbar.movedown ? <MoveDown as={as} size={size} /> : null}
      {post.actionbar.delete ? <Delete as={as} size={size} /> : null}
    </div>
  );
}

export default Actionbar;
