import React, { useState, useContext, useEffect } from "react";
import { User } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

const AuthUserContext = React.createContext();
const CookieNameUserInfo = "PostelUserInfo";

export const AuthUserProvider = ({ children }) => {
  const { isAuthenticated: logged, user } = useAuth0();
  const cookieUserInfo = Cookies.getJSON(CookieNameUserInfo);
  const [userInfo, setUserInfo] = useState(
    cookieUserInfo ? cookieUserInfo.userInfo : null
  );
  const [isError, setIsError] = useState(false);

  function setLoggedUser(loggedUser) {
    Cookies.set(CookieNameUserInfo, { userInfo: loggedUser });
    setUserInfo(loggedUser);
  }

  function clearLoggedUser() {
    setUserInfo(null);
    Cookies.remove(CookieNameUserInfo);
  }

  useEffect(() => {
    if (!logged) {
      clearLoggedUser();
    } else if (!userInfo) {
      (async () => {
        // Try looking up user by email
        try {
          let loggedUser = await User.getUserByEmail(user.email);
          setLoggedUser(loggedUser);
          return;
        } catch (error) {
          if (error.errorType !== "NotFoundError") {
            // log to logrocket
            setIsError(true);
            return;
          }
        }
        // First time login. Create user.
        try {
          let loggedUser = await User.createUser(
            user.name,
            user.nickname,
            user.email,
            user.picture
          );
          setLoggedUser(loggedUser);
          return;
        } catch (error) {
          // log to logrocket
          setIsError(true);
          return;
        }
      })();
    }
  }, [logged, userInfo, user]);

  if (isError) {
    // @TODO
    return <div>This error should be temporary.</div>;
  }

  return (
    <>
      <AuthUserContext.Provider value={{ logged, userInfo }}>
        {children}
      </AuthUserContext.Provider>
    </>
  );
};

export const useAuthUser = () => {
  return useContext(AuthUserContext);
};
