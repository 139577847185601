import React from "react";
import { useHistory } from "react-router";
import ProfilePicture from "../ProfilePicture";

function RFCListCard({ rfc }) {
  const history = useHistory();
  
  return (
    <li>
      <div
        className="d-flex flex-lg-row flex-column justify-content-between align-items-center border border-top-0 border-left-0 border-right-0 pcard"
        onClick={() => history.push(`/rfc/${rfc.id}`)}
      >
        <div
          className="d-flex p-0 mr-1 align-items-center justify-content-center"
          style={{ minWidth: "80px", minHeight: "80px" }}
        >
          <ProfilePicture url={rfc.author.profileImage} size="md" />
        </div>

        <div className="marginrightauto d-flex flex-column justify-content-center">
          <div className="rfc-last-status">{rfc.lastStatus}</div>

          <div className="rfc-author-name">{rfc.author.name}</div>

          <div className="text-secondary">{rfc.title}</div>
        </div>

        {/*<Badge variant="light" className="capitalize mx-5">
          {rfc.state}
  </Badge>*/}
      </div>
    </li>
  );
}

export default RFCListCard;
