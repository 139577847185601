import React from "react";
import ProfilePicture from "../../ProfilePicture";
import AuthorInfo from "../../../components/cards/PostAuthorInfo";
import {useAuthUser} from "../../../utils/AuthUser";
import { usePost } from "../../../utils/PostContext";

function PostPreview({ render }) {
  const { post } = usePost();
  const {userInfo} = useAuthUser();

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex">
          <div className="d-flex flex-column align-items-center post-left-margin">
            <ProfilePicture url={post.author.profileImage}size="md" />
            <div className="verticalLine my-1"></div>
          </div>
          <div className="d-flex flex-column px-2">
            <AuthorInfo author={post.author} />
            <div className="text-xl">{post.title || ""}</div>
            <div className="text-format">{post.content}</div>
            <div style={{ minHeight: "30px" }}></div>
          </div>
        </div>

        <div className="d-flex">
          <div className="d-flex flex-column align-items-center post-left-margin">
            <ProfilePicture url={userInfo.profileImage} size="md" />
          </div>
          <div className="d-flex flex-column flex-grow-1 px-2">
            <AuthorInfo author={userInfo} />
            <div className="pt-1">{render()}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostPreview;
