import React, { useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import EditSummaryComposer from "../composer/EditSummaryComposer";
import EditSectionComposer from "../composer/EditSectionComposer";
import { usePost } from "../../utils/PostContext";

function Edit({ as }) {
  const { post } = usePost();
  const [show, setShow] = useState(false);
  const showComposer = () => setShow(true);
  const hideComposer = () => setShow(false);

  function handleClickIcon(event) {
    event.stopPropagation();
    showComposer();
  }

  function handleHideComposer(event) {
    hideComposer();
  }

  return (
    <>
    <div className={post.actionbar.edit.icon.className}>
      <FiEdit3
        viewBox={post.actionbar.edit.icon.viewBox}
        strokeWidth={post.actionbar.edit.icon.strokeWidth}
        onClick={handleClickIcon}
      />

      {as === "RFCSummary" && (
        <EditSummaryComposer show={show} handleClose={handleHideComposer} />
      )}

      {as === "RFCSection" && (
        <EditSectionComposer show={show} handleClose={handleHideComposer} />
      )}
      </div>
    </>
  );
}

export default Edit;
