import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { usePost } from "../../utils/PostContext";
import { useRFC } from "../../utils/RFCContext";

function DropDownMenuItem({ item }) {
  const [show, setShow] = useState(false);
  const { post: section } = usePost();
  const history = useHistory();
  const ctx = { ...useRFC(), section, history };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {item.dialog ? item.dialog(show, handleClose, ctx) : null}

      <Dropdown.Item
        onClick={(event) => event.stopPropagation()}
        onSelect={(eventKey, event) => {
          if (item.dialog) handleShow();
          else item.onSelect(ctx);
        }}
        eventKey={item.key}
        key={item.key}
        //as="button"
        className="px-3 py-2"
      >
        {item.icon({
          strokeWidth: 1.5,
          className: "svg-steel",
          viewBox: "0 0 28 28",
        })}
        <span className="text-base pl-3">{item.label}</span>
      </Dropdown.Item>
    </>
  );
}

function DropDownMenu({ menuItems, menuToggle }) {
  return (
    <Dropdown drop="left">
      {menuToggle}
      <Dropdown.Menu className="shadow-lg border-0 py-0">
        {menuItems &&
          menuItems.map((item, index) => (
            <DropDownMenuItem key={item.key} item={item} />
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropDownMenu;
