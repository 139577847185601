import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { FiArchive } from "react-icons/fi";

function RFCListSelector() {
  const { listType } = useParams();
  const [key, setKey] = useState(listType || "draft");
  const history = useHistory();

  return (
    <Tab.Container
      activeKey={key}
      onSelect={(k) => {
        setKey(k);
        history.replace(`/rfcs/list/${k}`);
      }}
    >
      <Nav fill variant="pills" className="border-bottom-thick">
        <Nav.Item className="flex-row flex-grow-1">
          <Nav.Link eventKey="draft" className="">
            Open
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="flex-row flex-grow-1">
          <Nav.Link eventKey="active" className="">
            Active
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="flex-grow-0">
          <Nav.Link eventKey="archive" className="">
            <FiArchive />
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Tab.Container>
  );
}

export default RFCListSelector;
