import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import RFC from "../../../api/RFC";
import { useRFC } from "../../../utils/RFCContext";

function DeleteModal({ show, onHide }) {
  const history = useHistory();
  const { rfc } = useRFC();
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);
  
  let closeButton = isPending ? {} : { closeButton: true };
  let disabled = isPending ? { disabled: true } : {};

  async function handleDelete(e) {
    e.stopPropagation();
    setIsError(false);
    setIsPending(true);
    try {
      await RFC.Delete(rfc.id);
      history.replace("/home");
    } catch (error) {
      setIsError(true);
      setIsPending(false);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-50w"
      >
        <Modal.Header className="" {...closeButton}>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Collapse in={isError}>
            <div id="">
              <Alert show={isError} variant="danger">
                We encountered error while deleting RFC. Please try again
              </Alert>
            </div>
          </Collapse>
          <div className="text-dark">This will permanently delete RFC summary and sections</div>
        </Modal.Body>

        <Modal.Footer>
          {isPending ? null : (
            <Button variant="secondary" size="sm" onClick={onHide}>
              Close
            </Button>
          )}
          {isPending ? <Spinner animation="grow" variant="primary" /> : null}

          <Button variant="primary" size="sm" onClick={handleDelete} {...disabled}>
            {isPending ? "Deleting" : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
