import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ActivateModal from "./ActivateModal";
import CloseModal from "./CloseModal";

function Draft() {
  const [modal, setModal] = useState("");
  const [show, setShow] = useState(false);
  
  const showModal = (event) => {
    event.stopPropagation();
    setShow(true);
    setModal(event.target.name);
  }

  const hideModal = () => {
    setShow(false);
    setModal("");
  };

  return (
    <>
      <Button
        name="close"
        variant="outline-secondary"
        size="sm"
        className="align-self-start"
        onClick={showModal}
      >
        Close
        {modal === "close" && <CloseModal show={show} onHide={hideModal} />}
      </Button>
      <Button
        name="activate"
        variant="primary"
        size="sm"
        className="ml-1 align-self-start"
        onClick={showModal}
      >
        Make Active
        {modal === "activate" && <ActivateModal show={show} onHide={hideModal} />}
      </Button>
      

      {/** 
      <ButtonDropDown
        button={{ name: "publish", label: "Publish" }}
        menu={menu}
        onClick={handleClick}
      />
      */}
    </>
  );
}

export default Draft;
