import React from "react";
import { Button } from "react-bootstrap";
import RFCSectionsList from "./RFCSectionsList";
import AddSectionButton from "../AddSectionButton";
import { useRFC } from "../../utils/RFCContext";
import { modes } from "./modes";

function RFCSectionsRenderer({ sections, reload }) {
  const { rfc } = useRFC();
  const showButton = rfc.mode === modes.edit || rfc.mode === modes.commedit;
  return (
    <>
      <RFCSectionsList list={sections} reload={reload} />
      {showButton && (
        <div className="d-flex px-3 mb-5">
          <AddSectionButton>
            {/* 
            <FiPlusCircle
              className={""}
              viewBox="0 0 26 26"
              strokeWidth="2"
              stroke="red"
            />
            */}

            {
              <Button variant="outline-primary" size="sm" style={{borderRadius: "5px" }}>
                <span style={{fontWeight: 500}}>Add Section</span>
              </Button>
            }
          </AddSectionButton>
        </div>
      )}
    </>
  );
}

export default RFCSectionsRenderer;
