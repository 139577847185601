import React from "react";
import { Button, Navbar } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKiwiBird } from "@fortawesome/free-solid-svg-icons";

function NavBar() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <Navbar className="border-bottom justify-content-between">
      <Navbar.Brand href="#home">
        <FontAwesomeIcon
          icon={faKiwiBird}
          size="lg"
          style={{ color: "#14bc64" }}
          className="mr-3"
        />
        {/**
        <img
          alt=""
          src="logo512.png"
          width="30"
          height="30"
          className="d-inline-block align-top"
        /> */}{" "}
        Postel
      </Navbar.Brand>
      {!isAuthenticated && (
        <Button variant="primary" onClick={() => loginWithRedirect()}>
          Sign in
        </Button>
      )}
      {isAuthenticated && (
        <Button variant="primary" onClick={() => logoutWithRedirect({})}>
          Log out
        </Button>
      )}
    </Navbar>
  );
}

export default NavBar;
