import React from "react";

function PostAuthorInfo({ author }) {
  return (
    <span
      style={{ fontWeight: "bold", lineHeight: "1em" }}
    >
      {author.name}
    </span>
  );
}

export default PostAuthorInfo;
