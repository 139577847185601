import React, { useState } from "react";
import {useRFC} from "../../../utils/RFCContext";
import {usePost} from "../../../utils/PostContext";
import { Alert, Button, Modal } from "react-bootstrap";
import EditSectionComposerForm from "./EditSectionComposerForm";

function EditSectionComposer({ show, handleClose }) {
  const {rfc} = useRFC();
  const {post: section} = usePost();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasEdits, setHasEdits] = useState(false);
  const [hasContent, setHasContent] = useState(section.content.length > 0); // set to false even though we pre-load
  const formId = "EditSectionFrom";
  let submitButtonLabel1;
  let submitButtonLabel2;

  switch (rfc.state) {
    case "unpublished":
      submitButtonLabel1 = "Save";
      submitButtonLabel2 = "Saving";
      break;

    case "draft":
      submitButtonLabel1 = "Save & Publish";
      submitButtonLabel2 = "Publishing";
      break;

    // rfc.state other than unpublished and draft is an error
    default:
      throw new Error();
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      dialogClassName="rfc-composer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal.Header closeButton>
        <div className="d-block flow-column">
          <div>
            <h6 className="text-muted">{rfc.title}</h6>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Alert show={isError} variant="danger">
          Something went wrong. Please try again
        </Alert>
        <EditSectionComposerForm
          section={section}
          formId={formId}
          setIsError={setIsError}
          setIsSubmitting={setIsSubmitting}
          onHide={handleClose}
          setHasEdits={setHasEdits}
          setHasContent={setHasContent}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          form={formId}
          variant="primary"
          disabled={isSubmitting || !hasEdits || !hasContent}
        >
          {isSubmitting ? submitButtonLabel2 : submitButtonLabel1}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditSectionComposer;
