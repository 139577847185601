import React from "react";
import DropDownToggle from "../../dropdown/DropDownToggle";
import DropDownMenu from "../../dropdown/DropDownMenu";
import { usePost } from "../../../utils/PostContext";
import { useAuthUser } from "../../../utils/AuthUser";
import SectionMenuItems from "./RFCSectionCardMenuItems";

function RFCSectionCardDropDown() {
  const { userInfo } = useAuthUser();
  const { post : section} = usePost();
  const { isFirst, isLast } = usePost();
  let menuItems = { ...SectionMenuItems };

  if (section.author.id !== userInfo.id) {
    menuItems = null;
  } else if (section.state !== "unpublished" && section.state !== "draft") {
    menuItems = null;
  } else if (isFirst && isLast) {
    delete menuItems.moveup;
    delete menuItems.movedown;
  } else if (isFirst) {
    delete menuItems.moveup;
  } else if (isLast) {
    delete menuItems.movedown;
  } 

  return (
    menuItems && (
      <DropDownMenu
        menuToggle={<DropDownToggle />}
        menuItems={Object.values(menuItems)}
      /> /* Object.values returns array*/
    )
  );
}

export default RFCSectionCardDropDown;
