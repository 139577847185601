import React from "react";
import { Button, Modal } from "react-bootstrap";
import { handleApiErrors } from "../../../common/errors";
import {  FiArrowUp, FiArrowDown, FiTrash2 } from "react-icons/fi";
import RFC from "../../../api/RFC";

function confirmDelete(show, handleClose, ctx) {
  async function handleConfirm() {
    const { rfc, dispatchDeleteSection, section, history } = ctx;

    try {
      await RFC.DeleteSectionAndRemove(rfc.id, section.id);
      handleClose();
      dispatchDeleteSection(section);
    } catch (error) {
      handleApiErrors(error, history);
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      onClick={(event) => event.stopPropagation()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Section</Modal.Title>
      </Modal.Header>
      <Modal.Body>Section will be deleted permanently</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="warning" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

async function handleMoveUp({ rfc, section, dispatchMoveSectionUp, history }) {
  try {
    await RFC.MoveSectionUp(rfc.id, section.id);
    dispatchMoveSectionUp(section);
  } catch (error) {
    handleApiErrors(error, history);
  }
}

async function handleMoveDown({
  rfc,
  section,
  dispatchMoveSectionDown,
  history,
}) {
  try {
    await RFC.MoveSectionDown(rfc.id, section.id);
    dispatchMoveSectionDown(section);
  } catch (error) {
    handleApiErrors(history);
  }
}

const RFCSectionCardMenuItems = {
  /*
  edit: {
    icon: FiEdit3,
    label: "Edit",
    key: "Edit",
    dialog: editSectionDialog,
    onSelect: null,
  },
  */
  moveup: {
    icon: FiArrowUp,
    label: "Move Up",
    key: "MoveUp",
    dialog: null,
    onSelect: handleMoveUp,
  },
  movedown: {
    icon: FiArrowDown,
    label: "Move Down",
    key: "MoveDown",
    dialog: null,
    onSelect: handleMoveDown,
  },
  delete: {
    icon: FiTrash2,
    label: "Delete",
    key: "Delete",
    dialog: confirmDelete,
    onSelect: null,
  },
};

export default RFCSectionCardMenuItems;
