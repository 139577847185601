import React from "react";
import { useRFC } from "../../../utils/RFCContext";
import useAuthor from "../../../utils/Author";
import Unpublished from "./Unpublished";
import Draft from "./Draft";

function RFCActions() {
  const { rfc } = useRFC();
  const userIsAuthor = useAuthor(rfc.author.id);

  if (!userIsAuthor) return null;
    
  switch (rfc.state) {
    case "unpublished":
      return <Unpublished />;
    case "draft":
      return <Draft />
    default:
      return null;
  }
}

export default RFCActions;
