import React from "react";
import Logout from "./Logout";

function NavRight() {
  return (
    <aside className="pt-3 sticky-top">
      <Logout/>
    </aside>
  );
}

export default NavRight;
