import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Post from "../api/Post";
import { useParams } from "react-router";
import { PostHead } from "../components/Post";
import CommentsRenderer from "./CommentsRenderer";
import { CommentsRendererContext } from "../utils/CommentsRendererContext";
import { PostContext } from "../utils/PostContext";
import { useMainNav } from "../components/MainNav";
import { handleApiErrors } from "../common/errors";
import setPostHeadProps from "./setPostHeadProps";

// PostsRenderer renders
// Section as headcard followed by comments OR
// Comment as headcard followed by comments

const PostsRenderer = () => {
  const history = useHistory();
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [comment, renderComments] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { setStatus, setBackArrow } = useMainNav();

  useEffect(() => {
    async function fetchPost() {
      setBackArrow(true);
      setStatus("");
      setIsError(false);
      setIsLoading(true);
      try {
        
        let post = await Post.ReadPost(id);
        setPostHeadProps(post);
        setPost(post);
      } catch (error) {
        handleApiErrors(error, history);
        //setIsError(true);
      }
      setIsLoading(false);
    }
    fetchPost();
  }, [id, setBackArrow, setStatus, history]);

  return (
    <>
      {isError && <div> Something went wrong </div>}
      {isLoading ? (
        <Spinner animation="grow" variant="primary" />
      ) : (
        post &&
        post.post_type && (
          <CommentsRendererContext.Provider value={renderComments}>
            <PostContext.Provider value={{ post }}>
              <PostHead />
            </PostContext.Provider>
          </CommentsRendererContext.Provider>
        )
      )}

      {post && <CommentsRenderer id={post.id} updateWith={comment} />}
    </>
  );
};

export default PostsRenderer;
