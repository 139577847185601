import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import DocumentToggleSVG from "../media/icons/documentToggle";
import CommentToggleSVG from "../media/icons/commentToggle";

const ViewMode = {
  asDocument: 0, // View RFC as a document
  withComment: 1, // View RFC with comments
};

const viewModeState = atom({
  key: "viewModeState",
  default: ViewMode.withComment,
});

function useViewMode() {
  const viewMode = useRecoilValue(viewModeState);
  const setViewMode = useSetRecoilState(viewModeState);
  const viewAsDocument = viewMode === ViewMode.asDocument;
  const viewWithComments = viewMode === ViewMode.withComment;
  const toggleViewMode = () => setViewMode(
    viewAsDocument ? ViewMode.withComment : ViewMode.asDocument
  );

  return { viewMode, viewAsDocument, viewWithComments, toggleViewMode };
}

function ViewModeToggle() {
  const { viewMode, toggleViewMode } = useViewMode();
  const tip = viewMode === 0 ? "Show Comments" : "Show Preview";
  return (
    <OverlayTrigger
      delay={{ show: 1000, hide: 100 }}
      placement="left"
      overlay={
        <Popover>
          <Popover.Content>{tip}</Popover.Content>
        </Popover>
      }
    >
      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
          toggleViewMode();
        }}
      >
        {viewMode === ViewMode.asDocument
          ? CommentToggleSVG
          : DocumentToggleSVG}
      </div>
    </OverlayTrigger>
  );
}

export { useViewMode, ViewModeToggle };
