import React from "react";
import { RecoilRoot } from "recoil";
import "./scss/postel.scss";
import "./App.css";
import "./utils.css";
import "./odometer-theme-default.css";
import { Switch, Route } from "react-router-dom";
import Router, { AuthRoute } from "./utils/Router";
import { AuthUserProvider } from "./utils/AuthUser";
import AuthLayout from "./layout/AuthLayout";
import PublicHome from "./public/PublicHome";
import { useAuth0 } from "@auth0/auth0-react";
import { setGetAccessTokenSilently } from "./api";

function App() {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading ...!</div>;
  }

  setGetAccessTokenSilently(getAccessTokenSilently);

  return (
    <RecoilRoot>
      <AuthUserProvider>
        <Router>
          <Switch>
            <AuthRoute
              path={[
                "/home",
                "/my",
                "/rfcs",
                "/rfc",
                "/posts",
              ]}
              component={AuthLayout}
            />
            <Route path="/" component={PublicHome} />
          </Switch>
        </Router>
      </AuthUserProvider>
    </RecoilRoot>
  );
}

export default App;
