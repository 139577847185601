import React from "react";
import { Switch } from "react-router";
import { AuthRoute } from "../../utils/Router";
import RFCListSelector from "../RFCListSelector";
import RFCListRenderer from "../RFCListRenderer";
import RFCRenderer from "../RFCRenderer/index.js";
import PostsRenderer from "../PostsRenderer";

function MainContent() {
  return (
    <Switch>
      <AuthRoute
        exact
        path={["/home", "/rfcs/list/:listType"]}
      >
        <RFCListSelector />
        <RFCListRenderer />
      </AuthRoute>

      <AuthRoute path="/my">
        <RFCListRenderer />
      </AuthRoute>

      <AuthRoute exact path="/rfc/:id">
        <RFCRenderer />
      </AuthRoute>

      <AuthRoute exact path="/posts/:id">
        {<PostsRenderer />}
      </AuthRoute>
    </Switch>
  );
}

export default MainContent;
