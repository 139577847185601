import React, { useEffect, useState, useReducer } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { PostContext } from "../../utils/PostContext";
import { RFCContext } from "../../utils/RFCContext";
import { CommentsRendererContext } from "../../utils/CommentsRendererContext";
import RFC from "../../api/RFC";
import RFCSummary from "./RFCSummary";
import RFCSectionsRenderer from "./RFCSectionsRenderer";
import CommentsRenderer from "../CommentsRenderer";
import { useMainNav } from "../../components/MainNav";
import { handleApiErrors } from "../../common/errors";
import { useWhatChanged } from "@simbathesailor/use-what-changed";
import { getDefaultMode } from "./modes";
import setSummaryProps from "./setSummaryProps";
import useAuthor from "../../utils/Author";
import { Role } from "../../utils/Role";

function reduceSections(rfc, action) {
  let section;
  let index;
  let temp;

  switch (action.type) {
    case "init":
      return { ...action.payload };

    case "add":
      section = action.payload;
      rfc.sections.push(section.id);
      return { ...rfc }; // this clones rfc instead of updating old reference

    case "delete":
      section = action.payload;
      rfc.sections = rfc.sections.filter(
        (sectionId) => sectionId !== section.id
      );
      return { ...rfc };

    case "moveUp":
      section = action.payload;
      index = rfc.sections.indexOf(section.id);
      if (index < 0) return rfc; // this is an error
      temp = rfc.sections[index - 1];
      rfc.sections[index - 1] = rfc.sections[index];
      rfc.sections[index] = temp;
      return { ...rfc };

    case "moveDown":
      section = action.payload;
      index = rfc.sections.indexOf(section.id);
      if (index < 0) return rfc; // this is an error
      temp = rfc.sections[index + 1];
      rfc.sections[index + 1] = rfc.sections[index];
      rfc.sections[index] = temp;
      return { ...rfc };

    default:
      throw new Error();
  }
}

function RFCRenderer() {
  const { id } = useParams();
  const history = useHistory();
  const [rfc, dispatch] = useReducer(reduceSections, null);
  const [reload, forceUpdate] = useReducer((x) => x + 1, 0);
  const [comment, renderComments] = useState(null);
  const isAuthor = useAuthor(rfc?.author.id);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState(undefined);
  const [render, setRender] = useState(false);
  const { setStatus, setBackArrow } = useMainNav();

  // totalComments only counts comments in all sections
  // it doesn't include comments count on the RFCSummary
  // see comment in useEffect
  const [totalComments, incrementCommentCount] = useReducer(
    (totalComments, count) => {
      return totalComments + count;
    },
    0
  );

  const dispatchAddSection = (section) => {
    dispatch({ type: "add", payload: section });
    console.log("Section is added");
  }
    
  const dispatchDeleteSection = (section) =>
    dispatch({ type: "delete", payload: section });

  const dispatchMoveSectionUp = (section) =>
    dispatch({ type: "moveUp", payload: section });

  const dispatchMoveSectionDown = (section) =>
    dispatch({ type: "moveDown", payload: section });

  useWhatChanged(
    [id, reload, rfc, mode, setBackArrow, setStatus, totalComments, history],
    "id, reload, rfc, mode, setBackArrow, setStatus, totalComments, history"
  );

  useEffect(() => {
    setBackArrow(true);
    setStatus("Request For Comments");
  }, [setBackArrow, setStatus]);

  // Fetch RFC
  useEffect(() => {
    async function fetchRFC() {
      setIsLoading(true);
      try {
        let rfc = await RFC.Read(id);
        dispatch({ type: "init", payload: rfc });
        setIsLoading(false);
      } catch (error) {
        handleApiErrors(error, history);
      }
    }
    fetchRFC();
  }, [id, reload, history]);

  // Set mode depending on role and RFC state
  useEffect(() => {
    console.log("UseEffect triggered")
    let role = isAuthor ? Role.author : Role.reader;
    if (!rfc) return;
    rfc.mode = mode || getDefaultMode(role, rfc.state);
    setMode(rfc.mode);
    setSummaryProps(rfc);
    setRender(true);
  }, [rfc, isAuthor, mode]);

  if (isLoading) {
    return <Spinner animation="grow" variant="primary" />;
  }

  return (
    <>
      {render && (
        <RFCContext.Provider
          value={{
            rfc,
            mode,
            setMode,
            forceUpdate,
            incrementCommentCount,
            totalComments,
            dispatchAddSection,
            dispatchDeleteSection,
            dispatchMoveSectionUp,
            dispatchMoveSectionDown,
          }}
        >
          {/*<div>
          
            role=<b>{isAuthor ? "Author " : "Reader "}</b>
           mode = 
          {rfc.mode === 1 ? <b>Preview</b> : null}
          {rfc.mode === 2 ? <b>Edit</b> : null}
          {rfc.mode === 3 ? <b>Comment</b> : null}
          {rfc.mode === 4 ? <b>CommEdit</b> : null}
          </div>*/}
          <PostContext.Provider value={{ post: rfc }}>
            <RFCSummary rfc={rfc}/>
          </PostContext.Provider>

          {rfc.state !== "unpublished" &&
          !rfc.sections.length &&
          /*viewWithComments*/ 1 ? (
            <CommentsRenderer id={rfc.id} updateWith={comment} />
          ) : null}

          <CommentsRendererContext.Provider value={renderComments}>
            <RFCSectionsRenderer sections={rfc.sections} reload={reload} />
          </CommentsRendererContext.Provider>
        </RFCContext.Provider>
      )}
    </>
  );
}

export default RFCRenderer;
