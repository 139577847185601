import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import Post from "../../../api/Post";
import { usePost } from "../../../utils/PostContext";
//import { useRFC } from "../../../utils/RFCContext";
import { useCommentsRenderer } from "../../../utils/CommentsRendererContext";
import { useInlineComments } from "../../../common/context/InlineComments";
import { handleApiErrors } from "../../../common/errors";

// TODO: Form input validation
function CommentComposerForm({
  formId,
  setIsError,
  setIsSubmitting,
  setHasContent,
  onHide,
  incrementCommentCount,
}) {
  const history = useHistory();
  const [content, setContent] = useState("");
  const { post } = usePost();
  //const { addComments } = useRFC();
  const formControlComment = useRef(null);
  const renderComments = useCommentsRenderer();
  const setInlineComment = useInlineComments();

  function autoresize(element) {
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "px";
    element.scrollTop = element.scrollHeight;
  }

  useEffect(() => {
    formControlComment.current.focus();
    autoresize(formControlComment.current); // sets initial size to the contents of the textarea
  });

  function handleChangeComment(event) {
    setIsError(false);
    setContent(event.target.value);
    setHasContent(event.target.value.length > 0); // can't rely on "content" at this point
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsError(false);
    setIsSubmitting(true);

    try {
      let comment = await Post.CreateComment(post.id, content);
      // updates the count badge
      incrementCommentCount();

      // update total comments count across all sections
      //if (post.post_type === "Section") addComments(1);

      // render comments including newly created comment
      renderComments && renderComments(comment);
      // show inline comment
      setInlineComment && setInlineComment(comment);
      onHide();
    } catch (error) {
      setIsError(true);
      handleApiErrors(error, history);
    }
    setIsSubmitting(false);
  }

  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <Form.Control
          ref={formControlComment}
          onKeyDown={(e) => e.stopPropagation()} // to prevent losing focus
          className="p-0 form-control-content white-space-pre-wrap"
          as="textarea"
          rows="8"
          role="textbox"
          placeholder="Type your comment"
          onChange={handleChangeComment}
          onInput={(e) => autoresize(e.target)}
          value={content}
        />{" "}
      </Form>
    </>
  );
}

export default CommentComposerForm;
