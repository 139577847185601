import React from "react";
import { Dropdown } from "react-bootstrap";
import { FiMoreHorizontal } from "react-icons/fi";

function DropDownToggle() {
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="chevron"
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      onClickCapture={(e) => {}}
    >
      {/*
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-chevron-down"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
      */}
      {/**
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 10 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-more-horizontal"
      >
        <circle cx="12" cy="12" r="1"></circle>
        <circle cx="19" cy="12" r="1"></circle>
        <circle cx="5" cy="12" r="1"></circle>
      </svg>
 */}
      <FiMoreHorizontal viewBox="0 10 24 24"/>
    </div>
  ));

  return (
    <Dropdown.Toggle
      as={CustomToggle}
      id="dropdown-custom-components"
    ></Dropdown.Toggle>
  );
}

export default DropDownToggle;
