import { modes } from "./modes";

function setSummaryProps(rfc) {
  // Can user click summary?
  if (rfc.state === "unpublished" || rfc.sections.length === 0) {
    rfc.summary = {};
    rfc.summary.onClick = false;
    rfc.summary.className = "d-flex flex-column p-3 mb-3 border-bottom ";
  } else {
    rfc.summary = {};
    rfc.summary.onClick = true;
    rfc.summary.className = "d-flex flex-column p-3 mb-3 border-bottom pcard";
  }

  // Setup actionbar
  if (rfc.mode === modes.preview) {
    rfc.actionbar = null;
  } else {
    rfc.actionbar = {};
    if (rfc.mode === modes.comment || rfc.mode === modes.commedit) {
      rfc.actionbar.comment = {
        icon: {
          className: "mt-2",
          viewBox: "2 2 20 20",
          strokeWidth: "1.5"
        }
      };
    } else {
      rfc.actionbar.comment = null;
    }

    if (rfc.mode === modes.edit || rfc.mode === modes.commedit) {
      rfc.actionbar.edit = {
        icon: {
          className: "mt-2 mr-action-icon",
          viewBox: "0 0 22 22",
          strokeWidth: "1.6"
        }
      };
    } else {
      rfc.actionbar.edit = null;
    }

    rfc.actionbar.moveup = false;
    rfc.actionbar.movedown = false;
    rfc.actionbar.delete = false;
  }
}

export default setSummaryProps;
