import React from "react";
import { FiChevronsUp } from "react-icons/fi";
import { usePost } from "../../utils/PostContext";
import { useRFC } from "../../utils/RFCContext";
import RFC from "../../api/RFC";

function MoveUp() {
  const { post } = usePost();
  const { rfc, dispatchMoveSectionUp } = useRFC();

  async function handleMoveUp() {
    try {
      await RFC.MoveSectionUp(rfc.id, post.id);
      dispatchMoveSectionUp(post);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={post.actionbar.moveup.icon.className}>
      <FiChevronsUp
        viewBox={post.actionbar.moveup.icon.viewBox}
        strokeWidth={post.actionbar.moveup.icon.strokeWidth}
        onClick={event => {
          event.stopPropagation();
          handleMoveUp();
        }}
      />
    </div>
  );
}

export default MoveUp;
