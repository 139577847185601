import React from 'react';
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { useAuthUser } from "./AuthUser";

export const AuthRoute = ({ children, ...rest }) => {
  const { logged, userInfo } = useAuthUser();
  if (logged === null) {
    return <div>Loading...</div>;
  } else if (logged === false) {
    return <Redirect to="/" push />;
  } else if (userInfo === null) {
    return <div>Logging in ... </div>;
  }
  return <Route {...rest}>{children}</Route>;
};

const Router = ({ children }) => {
  return (
    <BrowserRouter>
      <Route
        render={({ location: { pathname, search, hash } }) =>
          pathname.slice(-1) === "/" && pathname !== "/" ? (
            <Redirect to={`${pathname.slice(0, -1)}${search}${hash}`} />
          ) : (
            children
          )
        }
      />
    </BrowserRouter>
  );
};

export default Router;
