import React from "react";
import { Image } from "react-bootstrap";

function ProfilePicture({ url, size }) {
  let width, height;

  switch (size) {
    case "sm":
      width = height = 32;
      break;
    case "md":
      width = height = 48;
      break;
    case "lg":
      width = height = 56;
      break;
    case "xl":
      width = height = 64;
      break;
    default:
      width = height = 48;
      break;
  }

  return (
    <Image
      className="rounded-circle"
      src={url}
      alt="Default Profile"
      width={width}
      height={height}
    />
  );
}

export default ProfilePicture;
