import React from "react";
import { Dropdown, Button, Image } from "react-bootstrap";
import { useAuthUser } from "../../utils/AuthUser";
import { useAuth0 } from "@auth0/auth0-react";
import config from "../../config";
import { FiChevronDown } from "react-icons/fi";

const UserSettingsButton = React.forwardRef(({children, onClick}, ref) => {
  const { userInfo } = useAuthUser();

  return (
    <Button variant="ghost" ref={ref} onClick={onClick}>
      <Image src={userInfo.profileImage} roundedCircle height={36} width={36} />
      <span className="mx-1 text-sm text-weight-700 d-none d-xl-inline">{userInfo.name}</span>
      <FiChevronDown viewBox="0 0 24 24" />
    </Button>
  );
});

function Logout() {
  const { logout } = useAuth0(); // signout auth0 user

  const logoutWithRedirect = () =>
    logout({
      client_id: config.auth0.clientId,
      //federated: true,
      returnTo: window.location.origin,
    });

  return (
    <Dropdown>
      <Dropdown.Toggle as={UserSettingsButton} id="user-dropdown" />
      <Dropdown.Menu className="mt-1 p-2">
        <Dropdown.Item
          onSelect={() => {
            logoutWithRedirect();
          }}
        >
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Logout;
