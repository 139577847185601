import { useAuthUser } from "./AuthUser";

function useAuthor(userId) {
  const { userInfo } = useAuthUser();

  if (!userId) return false;

  return userId === userInfo.id;
}

export default useAuthor;
