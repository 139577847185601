import React from "react";
import { Button } from "react-bootstrap";
import { useRFC } from "../../utils/RFCContext";
import { useRole } from "../../utils/Role";
import { modes, getNextModes } from "./modes";

function RFCModeActions() {
  const { rfc, mode, setMode } = useRFC();
  let role = useRole(rfc.author.id);
  const nextModes = getNextModes(role, rfc.state, mode);

  return (
    <>
      {nextModes.map(item => {
        if (false && item === modes.preview) {
          return (
            <Button
              key={item}
              className="ml-1 align-self-start"
              variant="outline-primary"
              size="sm"
              onClick={e => {
                e.stopPropagation();
                setMode(item);
              }}
            >
              Preview
            </Button>
          );
        } else if (item === modes.edit) {
          return (
            <Button
              key={item}
              className="ml-1 align-self-start"
              variant="outline-primary"
              size="sm"
              onClick={e => {
                e.stopPropagation();
                setMode(item);
              }}
            >
              Edit
            </Button>
          );
        } else if (item === modes.comment) {
          return (
            <Button
              key={item}
              className="ml-1 align-self-start"
              variant="outline-primary"
              size="sm"
              onClick={e => {
                e.stopPropagation();
                setMode(item);
              }}
            >
              Comment
            </Button>
          );
        } else {
          return null;
        }
      })}
    </>
  );
}

export default RFCModeActions;
