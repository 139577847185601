import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavLeft from '../components/NavLeft';
import NavRight from '../components/NavRight';
import { MainNav } from '../components/MainNav';
import MainContent from '../components/MainContent';

function AuthLayout() {
  return (
    <>
      <Container fluid className="h-screen">
        <Row className="h-100">
          <Col xs={2} md={3}>
            <NavLeft/>
          </Col>
          <Col xs={8} md={6} className="border-left border-right px-0">
            <MainNav/>
            <MainContent/>
          </Col>
          <Col xs={2} md={3}>
            <NavRight />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AuthLayout;
