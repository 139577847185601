import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router";
import { useRFC } from "../../utils/RFCContext";
import { PostContext } from "../../utils/PostContext";
import useAuthor from "../../utils/Author";
import Post from "../../api/Post";
import RFCSectionCard from "../cards/RFCSectionCard";
import { modes } from "../RFCRenderer/modes";

function RFCSection({ sectionId, isFirst, isLast, reload }) {
  const { rfc, mode, incrementCommentCount } = useRFC();
  const history = useHistory();
  const [post, setPost] = useState(null);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const isAuthor = useAuthor(rfc?.author.id);

  function refreshSection() {
    forceUpdate();
  }

  useEffect(() => {
    async function fetchSection() {
      try {
        let post = await Post.ReadPost(sectionId);

        post.mode = mode;
        if (isAuthor) {
          post.vLine = false; //true;
        } else {
          post.vLine = false; //isLast ? false : true;
        }

        if (post.state === "unpublished") {
          post.className = "";
          post.onClick = null;
        } else {
          post.className = "p-0 m-0 pcard";
          post.onClick = () => history.push(`/posts/${post.id}`);
        }

        if (post.mode === modes.preview) {
          post.actionbar = false;
        } else {
          post.actionbar = {};
          if (post.mode === modes.comment || post.mode === modes.commedit) {
            post.actionbar.comment = {
              icon: {
                className: "mt-2",
                viewBox: "0 0 26 26",
                strokeWidth: "1.5"
              }
            };
          } else {
            post.actionbar.comment = null;
          }

          if (post.mode === modes.edit || post.mode === modes.commedit) {
            post.actionbar.edit = {
              icon: {
                className: "mt-2 mr-action-icon",
                viewBox: "0 0 26 26",
                strokeWidth: "1.5"
              }
            };
          } else {
            post.actionbar.edit = null;
          }

          if (
            !isFirst &&
            (post.mode === modes.edit || post.mode === modes.commedit)
          ) {
            post.actionbar.moveup = {
              icon: {
                className: "mt-2 mr-action-icon",
                viewBox: "0 0 26 26",
                strokeWidth: "1.5"
              }
            };
          } else {
            post.actionbar.moveup = null;
          }

          if (
            !isLast &&
            (post.mode === modes.edit || post.mode === modes.commedit)
          ) {
            post.actionbar.movedown = {
              icon: {
                className: "mt-2 mr-action-icon",
                viewBox: "0 0 26 26",
                strokeWidth: "1.5"
              }
            };
          } else {
            post.actionbar.movedown = null;
          }

          if (post.mode === modes.edit || post.mode === modes.commedit) {
            post.actionbar.delete = {
              icon: {
                className: "mt-2 mr-action-icon",
                viewBox: "0 0 26 26",
                strokeWidth: "1.5"
              }
            };
          } else {
            post.actionbar.delete = null;
          }
        }

        setPost(post);
        incrementCommentCount(post.comment_count);
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
    fetchSection();
  }, [
    ignored,
    sectionId,
    history,
    isFirst,
    mode,
    isLast,
    isAuthor,
    reload,
    incrementCommentCount
  ]);

  if (!post) {
    return null;
  } else {
    return (
      <li>
        <PostContext.Provider value={{ post, refreshSection, isFirst, isLast }}>
          <RFCSectionCard id={sectionId} />
        </PostContext.Provider>
      </li>
    );
  }
}

export default RFCSection;
