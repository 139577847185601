import React from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import NavBar from "./NavBar";
import { FiTwitter } from "react-icons/fi";
import githubLogo from "./GitHub_Logo.png";

function PublicHome() {
  return (
    <>
      <NavBar />

      <Container fluid className="h-screen">
        <Row className="h-100 mt-5">
          <Col xs={2} md={3}></Col>
          <Col xs={8} md={6} className=" px-0">
            <div className="">
              <h1 className="display-3">
                Propose ideas. Get Feedback. Iterate.
              </h1>
              <p className="w-50 text-secondary">
                {" "}
                Write awesome Request For Comments (RFCs). Publish software
                designs, ideas or anything else. Engage community in a feedback
                loop. Build knowledge base.
              </p>{" "}
            </div>
            <div className="mt-4">
              Sign-Up with <img src={githubLogo} height="10%" width="10%" alt="Github"/>
            </div>
            <div className="mt-3">
              <span className="pr-2">Follow us on Twitter</span>
              <a href="https://twitter.com/Posteldotio">
                <FiTwitter fill="#14bc64" style={{ stroke: "#14bc64" }} />
              </a>
            </div>
            {/*<div className="mt-3">
              <span className="pr-2">Contact us</span>
              <a href="mailto:support@postel.io">
                <FiSend style={{ stroke: "#14bc64" }} />
              </a>
            </div>*/}
          </Col>
          <Col xs={2} md={3}></Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Navbar className="ml-5" fixed="bottom" variant="light">
            <Nav className="ml-auto">
              <Nav.Link href="#features">Terms</Nav.Link>
            </Nav>
          </Navbar>
        </Row>
      </Container>
    </>
  );
}

export default PublicHome;
