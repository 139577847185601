import React, { useReducer } from "react";
import { usePost, PostContext } from "../../utils/PostContext";
import useAuthor from "../../utils/Author";
import ProfilePicture from "../ProfilePicture";
import AuthorInfo from "../../components/cards/PostAuthorInfo";
import Actionbar from "../Actionbar/";
import AddSectionButton from "../AddSectionButton";
import { modes } from "../RFCRenderer/modes"; // @TODO move to common area
import { useViewMode } from "../ViewMode";
import { InlineCommentsProvider } from "../../common/context/InlineComments";
import { CSSTransition } from "react-transition-group";

function PostLeft({ as, displayVerticalLine, displayAddSectionBtn }) {
  let picSize;
  const { post } = usePost();

  if (post.mode !== modes.comment && post.mode !== modes.commedit) {
    return null;
  }

  switch (as) {
    case "RFCSummary":
      picSize = "lg";
      break;
    case "RFCSection":
      picSize = "md";
      break;
    case "Comment":
      picSize = "md";
      break;
    case "InlineComment":
      picSize = "sm";
      break;
    default:
      picSize = "medium";
  }

  return (
    <CSSTransition
      in={true}
      timeout={500}
      classNames="comments-transition"
      unmountOnExit
    >
      <div className="d-flex flex-column align-items-center pr-2">
        <div className="d-flex justify-content-center post-left-margin">
          <ProfilePicture url={post.author.profileImage} size={picSize} />
        </div>
        {displayVerticalLine ? <div className="verticalLine"></div> : null}
        {displayAddSectionBtn ? <AddSectionButton /> : null}
      </div>
    </CSSTransition>
  );
}

function PostRight({ dropdown }) {
  const { post } = usePost();

  if (post.mode !== modes.comment) {
    return null;
  }
  return <div className="d-flex flex-column ml-auto">{dropdown}</div>;
}

function CreatedOn(date) {
  return new Date(date).toLocaleDateString("en-gb", {
    month: "long",
    day: "numeric",
  });
} // 18

function PostHeader() {
  const { post } = usePost();

  if (post.mode !== modes.comment && post.mode !== modes.commedit) {
    return null;
  }

  return (
    <>
      <div className="d-flex align-items-top">
        <AuthorInfo author={post.author} />
        &nbsp;&nbsp;&nbsp;
        <span className="text-sm text-steel">{CreatedOn(post.created_on)}</span>
      </div>
    </>
  );
}

function PostFooter() {
  const { post } = usePost();

  if (post.mode === modes.preview) {
    return null;
  }

  return (
    <>
      <Actionbar as={"RFCSection"} />
    </>
  );
}

function PostMiddle({as}) {
  const { post } = usePost();
  let padding = "";

  if (as === "RFCSection") {
    padding = " pb-3"
  }
  return (
    <div className={"d-flexflex-column flex-grow-1" + padding}>
      <PostHeader />
      <div className="text-lg text-weight-500">{post.title}</div>
      <div className="text-format">{post.content}</div>
      <PostFooter />
    </div>
  );
}

function reducer(state, comment) {
  return comment;
}

function Post({ as, dropdown }) {
  const { post, isLast } = usePost();
  const { viewWithComments } = useViewMode();
  const [hasInlineComments, setInlineComment] = useReducer(reducer, null);
  let titleClassName;
  let contentClassName = "post-content-text pt-1";
  let padding = " px-3 pt-2";
  let border = "";
  let displayVerticalLine = false;
  let displayAddSectionBtn = false;
  let userIsAuthor = useAuthor(post.author.id);

  switch (as) {
    case "RFCSummary":
      padding = " px-3 pt-4 pb-3";
      titleClassName = "text-2xl";
      border = "";
      contentClassName = "text-xl text-format pt-3";
      displayVerticalLine = viewWithComments && post.vLine;
      displayAddSectionBtn = userIsAuthor;
      break;

    case "RFCSection":
      titleClassName = "post-title-normal";
      displayVerticalLine = isLast? false : true;
      displayAddSectionBtn = userIsAuthor && isLast;
      border = " "; // border-bottom if edit
      padding = "px-3"; // bt-3 if edit
      break;

    case "RFCHead":
      titleClassName = "post-title-large mb-3";
      border = " border-bottom-thick";
      break;

    case "CommentHead":
      border = " border-bottom-thick";
      break;

    case "Comment":
      if (hasInlineComments) {
        border = "";
        displayVerticalLine = viewWithComments;
      } else {
        border = " border-bottom";
        displayVerticalLine = false;
      }
      //
      padding = " px-3 py-2 ";
      break;

    case "InlineComment":
      if (hasInlineComments) {
        border = "";
        displayVerticalLine = viewWithComments;
      } else {
        border = " ";
        displayVerticalLine = false;
      }
      padding = " pl-3 pr-3 pb-3 pt-0";
      break;

    default:
      break;
  }

  return (
    <>
      <InlineCommentsProvider as={as} setInlineComment={setInlineComment}>
        <div className={"d-flex flex-column " + padding + border}>
          <div className="d-flex flex-row">
            <PostLeft
              as={as}
              displayVerticalLine={displayVerticalLine}
              displayAddSectionBtn={displayAddSectionBtn}
            />
            <PostMiddle
              as={as}
              titleClassName={titleClassName}
              contentClassName={contentClassName}
              displayAddSectionBtn={displayAddSectionBtn}
            />
            <PostRight dropdown={dropdown} />
          </div>
        </div>

        {false && hasInlineComments ? (
          <PostContext.Provider value={{ post: hasInlineComments }}>
            <Post as="InlineComment" />
          </PostContext.Provider>
        ) : null}
      </InlineCommentsProvider>
    </>
  );
}

export default Post;
