import React from "react";
import RFCSection from "./RFCSection";

function RFCSectionsList({ list, reload }) {
  const last_index = list.length - 1;

  return (
    <ul className="p-0 m-0 list-unstyled">
        {list &&
          list.map((sectionId, index) => (
              <RFCSection
                key={sectionId}
                sectionId={sectionId}
                isFirst={index === 0}
                isLast={index === last_index}
                reload = {reload}
              />
          ))}
    </ul>
  );
}

export default RFCSectionsList;
