import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./config";
import history from "./utils/history";

const onRedirectCallback = appState => {
  console.log(
    "onRedirectCallback",
    "appState = ",
    appState,
    "window.location.pathname = ",
    window.location.pathname
  );
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

function AppHeader() {
  return (
    <Helmet>
      <title>{config.app.title}</title>
    </Helmet>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      audience={config.auth0.audience}
      redirectUri={`${window.location.origin}${config.auth0.onLoginRedirectUri}`}
      onRedirectCallback={onRedirectCallback}
    >
      <AppHeader />
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
