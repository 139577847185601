import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import AddSectionComposerForm from "./AddSectionComposerForm";

function AddSectionComposer({ show, handleClose, rfc }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const formId = "AddSectionFrom";
  let submitButtonLabel1;
  let submitButtonLabel2;

  switch (rfc.state) {
    case "unpublished":
      submitButtonLabel1 = "Save";
      submitButtonLabel2 = "Saving";
      break;

    case "draft":
      submitButtonLabel1 = "Add & Publish";
      submitButtonLabel2 = "Adding";
      break;

    case "active":
    default:
      break;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      dialogClassName="rfc-composer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal.Header closeButton>
        <div className="d-block flow-column">
          <div>
            <h5>Add Section</h5>
          </div>
          <div>
            <h6 className="text-secondary">{rfc.title}</h6>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Alert show={isError} variant="danger">
          Something went wrong. Please try again
        </Alert>
        <AddSectionComposerForm
          formId={formId}
          setIsError={setIsError}
          setIsSubmitting={setIsSubmitting}
          onHide={handleClose}
          setHasContent={setHasContent}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          form={formId}
          variant="primary"
          disabled={isSubmitting || !hasContent}
        >
          {isSubmitting ? submitButtonLabel2 : submitButtonLabel1}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddSectionComposer;
