import React from "react";
import ProfilePicture from "../ProfilePicture";
import AuthorInfo from "../../components/cards/PostAuthorInfo";
import { usePost } from "../../utils/PostContext";
import Actionbar from "../Actionbar/";

function PostHead() {
  const { post } = usePost();
  return (
    <>
      <div className="d-flex flex-column p-3 mb-3 border-bottom">
        <div className="d-flex align-items-top">
          <div className="">
            <ProfilePicture url={post.author.profileImage} size="lg" />
          </div>
          <div className="d-flex flex-column px-2">
            <div className="d-flex align-items-top">
              <AuthorInfo author={post.author} />
            </div>
            <div className="d-flex align-items-center">
              <div className="text-sm text-muted">{post.state || ""}</div>
            </div>
          </div>
        </div>

        {post.title ? (
          <div className="d-flex justify-content-between align-contents-top pt-3">
            <div className="text-2xl"> {post.title}</div>
          </div>
        ) : null}

        <div className="text-xl text-format pt-3"> {post.content}</div>
        <div className="mt-3 mb-2 p-3 border-top border-bottom">
          <b>{post.comment_count}</b> Comments
        </div>
        <div className="pl-1">
          <Actionbar as="PostHead" size="lg" />{" "}
        </div>
      
      </div>
    </>
  );
}

export default PostHead;
