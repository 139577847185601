import React, { useState } from "react";
import { Alert, Button, Modal, ProgressBar } from "react-bootstrap";
import { useRFC } from "../../../utils/RFCContext";
import EditSummaryComposerForm from "./EditSummaryComposerForm";

function EditSummaryComposer({ show, handleClose }) {
  const { rfc } = useRFC();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasTitle, setHasTitle] = useState(rfc.title.length > 0);
  const [contentLength, setContentLength] = useState(rfc.content.length > 0);
  const formId = "EditSummaryForm";
  let submitButtonLabel1;
  let submitButtonLabel2;

  switch (rfc.state) {
    case "unpublished":
      submitButtonLabel1 = "Save";
      submitButtonLabel2 = "Saving";
      break;

    case "draft":
      submitButtonLabel1 = "Save & Publish";
      submitButtonLabel2 = "Publishing";
      break;

    // rfc.state other than unpublished and draft is an error
    default:
      throw new Error();
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      dialogClassName="rfc-composer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal.Header className="border-bottom-0" closeButton />
      <ProgressBar
        now={(contentLength * 100) / 300}
        variant={contentLength >= 300 ? "warning" : ""}
        style={{ height: "2px" }}
      />
      <Modal.Body>
        <Alert show={isError} variant="danger">
          Something went wrong. Please try again
        </Alert>
        <EditSummaryComposerForm
          formId={formId}
          setIsError={setIsError}
          setIsSubmitting={setIsSubmitting}
          onHide={handleClose}
          setHasTitle={setHasTitle}
          setContentLength={setContentLength}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          {300 - contentLength ? 300 - contentLength : ""}
        </div>

        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          form={formId}
          variant="primary"
          disabled={isSubmitting || !hasTitle || !contentLength}
        >
          {isSubmitting ? submitButtonLabel2 : submitButtonLabel1}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditSummaryComposer;
