import React from "react";
import { Button, Modal } from "react-bootstrap";
import DropDownToggle from "../../dropdown/DropDownToggle";
import DropDownMenu from "../../dropdown/DropDownMenu";
import Post from "../../../api/Post";
import { usePost } from "../../../utils/PostContext";
import useAuthor from "../../../utils/Author";
import { handleApiErrors } from "../../../common/errors";

function confirmDeleteDialog(show, handleClose, ctx) {
  async function handleConfirm() {
    const { post, history } = ctx;
    try {
      let id = post.id;
      await Post.DeleteComment(id);
      handleClose();
    } catch (error) {
      handleApiErrors(error, history);
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>Contents will be permanently deleted</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const menuItems = [
  {
    label: "Delete",
    key: "Delete",
    dialog: confirmDeleteDialog,
    onSelect: null,
  },
];

function CommentCardDropDown() {
  const { post } = usePost();
  const userIsAuthor = useAuthor(post.author._id); // _id should be replaced with id

  if (!userIsAuthor) return null;

  return <DropDownMenu menuToggle={<DropDownToggle />} menuItems={menuItems} />;
}

export default CommentCardDropDown;
