import React, { useState, useRef, useEffect } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import PostPreview from "./PostPreview";
import CommentComposerForm from "./CommentComposerForm";

function CommentComposer({ show, handleClose, incrementCommentCount }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const [isError, setIsError] = useState(false);
  const formId = "CommentForm";
  const formRef = useRef(null);

  useEffect(() => {
    if (show) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      scrollable={true}
      dialogClassName="rfc-composer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal.Header className="" closeButton />

      <Modal.Body>
        <Alert show={isError} variant="danger">
          Something went wrong. Please try again
        </Alert>
        <PostPreview
          render={() => {
            return (
              <div ref={formRef}>
                <CommentComposerForm
                  formId={formId}
                  setIsError={setIsError}
                  setIsSubmitting={setIsSubmitting}
                  setHasContent={setHasContent}
                  onHide={handleClose}
                  incrementCommentCount={incrementCommentCount}
                />
              </div>
            );
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          form={formId}
          variant="primary"
          disabled={isSubmitting || !hasContent}
        >
          {isSubmitting ? "Posting" : "Post"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CommentComposer;
