import React from "react";

const BackArrowSVG = (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    strokeWidth="0.3"
    className="bi bi-arrow-left svg-green-solid"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
  </svg>
);

export default BackArrowSVG;
