import useAuthor from "./Author";
import { useState, useEffect } from "react";

const Role = {
  author: 0,
  reader: 1
};

function useRole(id) {
  const [role, setRole] = useState(Role.reader);
  const isAuthor = useAuthor(id);

  useEffect(() => {
    if (isAuthor) {
      setRole(Role.author);
    } else {
      setRole(Role.reader)
    }
  }, [isAuthor]);

  return role;
}

export { Role, useRole };