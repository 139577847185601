import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useRFC } from "../../../utils/RFCContext";
import RFC from "../../../api/RFC";
import { handleApiErrors } from "../../../common/errors";

// TODO: Form input validation
function AddSectionComposerForm({
  formId,
  setIsError,
  setIsSubmitting,
  onHide,
  setHasContent,
}) {
  const history = useHistory();
  const { rfc, dispatchAddSection /*, forceUpdate */} = useRFC();
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  const formCtrlTitle = useRef(null);

  function autoresize(element) {
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "px";
    element.scrollTop = element.scrollHeight;
  }

  useEffect(() => {
    formCtrlTitle.current.focus();
  }, [title]);

  function handleChangeTitle(event) {
    setIsError(false);
    setTitle(event.target.value); // can't rely on title at this point
  }

  function handleChangeContent(event) {
    setIsError(false);
    setContent(event.target.value);
    setHasContent(event.target.value.length); // can't rely on content at this point
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsError(false);
    setIsSubmitting(true);

    try {
      

      let section = await RFC.CreateSectionAndAdd(
        rfc.id,
        title,
        content,
      );
      dispatchAddSection(section);
      //forceUpdate();
      onHide();
    } catch (error) {
      setIsError(true);
      if (error.statusCode < 500) handleApiErrors(error, history);
    }
    setIsSubmitting(false);
  }

  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <Form.Control
          ref={formCtrlTitle}
          onKeyDown={(e) => e.stopPropagation()} // to prevent losing focus
          className="pt-0 form-control-title"
          as="textarea"
          rows="1"
          placeholder="Title (optional)"
          onChange={handleChangeTitle}
          value={title}
        />
        <Form.Control
          onKeyDown={(e) => e.stopPropagation()} // to prevent losing focus
          className="form-control-content white-space-pre-wrap"
          as="textarea"
          role="textbox"
          rows="8"
          placeholder="Type text of the section"
          onChange={handleChangeContent}
          onInput={(e) => autoresize(e.target)}
        />{" "}
      </Form>
    </>
  );
}

export default AddSectionComposerForm;
