import React, { useState, useReducer } from "react";
import { FiMessageCircle } from "react-icons/fi";
import CommentComposer from "../composer/CommentComposer";
import { usePost } from "../../utils/PostContext";
import Odometer from "react-odometerjs";

function Comment({ as }) {
  const { post } = usePost();
  const [show, setShow] = useState(false);
  const showComposer = () => setShow(true);
  const hideComposer = () => setShow(false);
  const [count, incrementCount] = useReducer(
    (count) => count + 1,
    0,
    () => post.comment_count
  );

  function handleClickIcon(event) {
    event.stopPropagation();
    showComposer();
  }

  function handleHideComposer(event) {
    hideComposer();
  }

  return (
    <div className="d-flex align-items-center mr-action-icon">
      <FiMessageCircle
        className={post.actionbar.comment.icon.className}
        viewBox={post.actionbar.comment.icon.viewBox}
        strokeWidth={post.actionbar.comment.icon.strokeWidth}
        onClick={handleClickIcon}
      />

      {as !== "PostHead" && count > 0 ? (
        <>
          <div className="pl-2 mt-2 text-base text-steel">
            <Odometer
              value={count}
              duration={1000}
              format="d"
              class="color-steel"
            />
          </div>
        </>
      ) : null}

      <CommentComposer
        show={show}
        handleClose={handleHideComposer}
        incrementCommentCount={incrementCount}
      />
    </div>
  );
}

export default Comment;
