import React, { useState } from "react";
import { Navbar, Nav, Tab } from "react-bootstrap";
import { useHistory } from "react-router";
import RequestForComments from "../RequestForComments";
import { FaKiwiBird } from "react-icons/fa";

function LeftNav() {
  const [key, setKey] = useState("/home");
  const history = useHistory();
  return (
    <aside className="d-flex flex-column align-items-center sticky-top">
      <Navbar>
        <Navbar.Brand href="/" className="mr-0 mr-sm-2">
          <div className="d-flex align-items-center">
            <FaKiwiBird fill="#14bc64" />
          </div>
        </Navbar.Brand>
      </Navbar>
      <RequestForComments />

      <Tab.Container
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
          history.replace(k);
        }}
      >
        <Nav variant="basic" className="flex-column pt-2 align-items-start">
          <Nav.Item className="font-weight-bold text-lg">
            <Nav.Link eventKey="/home">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item className="font-weight-bold text-lg">
            <Nav.Link eventKey="/my">My Drafts</Nav.Link>
          </Nav.Item>
        </Nav>
      </Tab.Container>
    </aside>
  );
}

export default LeftNav;
