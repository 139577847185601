import { Role } from "../../utils/Role";

const modes = {
  preview: 1,
  edit: 2,
  comment: 3,
  commedit: 4
};

function getDefaultMode(role, state) {
  console.log("getDefaultMode role=", role)
  if (role === Role.author) {
    switch (state) {
      case "unpublished":
        return modes.edit;
      case "draft":
        return modes.commedit;
      default:
        return modes.comment;
    }
  } else {
    return modes.comment
  }
}

function getNextModes(role, state, currMode) {
  switch (role) {
    case Role.author:
      switch (state) {
        case "unpublished":
          switch (currMode) {
            case modes.preview:
              return [modes.edit];
            default:
              return [modes.preview];
          }
        case "draft":
          switch (currMode) {
            case modes.preview:
              return [modes.edit, modes.comment];
            case modes.edit:
              return [modes.preview, modes.comment];
            case modes.comment:
              return [modes.preview, modes.edit];
            default:
              return [modes.preview];
          }
        case "active":
        case "closed":
          switch (currMode) {
            case modes.preview:
              return [modes.comment];
            case modes.comment:
            default:
              return [modes.preview];
          }
        default:
          return [modes.preview];
      }
    case Role.reader:
      switch (currMode) {
        case modes.preview:
          return [modes.comment];
        case modes.comment:
        default:
          return [modes.preview];
      }
    default:
      return [modes.preview];
  }
}

export { modes, getDefaultMode, getNextModes };
