import React from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory, useRouteMatch} from "react-router";
import BackArrowSVG from "../../media/icons/backarrow";
import { ViewModeToggle } from "../ViewMode";

const backArrowState = atom({
  key: "backArrowState",
  default: true,
});

const statusState = atom({
  key: "statusState",
  default: "",
});

function useMainNav() {
  const setStatus = useSetRecoilState(statusState);
  const setBackArrow = useSetRecoilState(backArrowState);
  return { setStatus, setBackArrow };
}

function Status() {
  const status = useRecoilValue(statusState);
  return <div className="m-3 navstatus">{status}</div>;
}

function MainNav() {
  const setStatus = useSetRecoilState(statusState);
  const backarrow = useRecoilValue(backArrowState);
  const history = useHistory();

 // console.log("MainNav location", location);

  const matchRFCs = useRouteMatch({
    path: ["/home", "/rfcs/list/:listType"],
    strict: true,
    sensitive: true,
  });

  const matchMy = useRouteMatch({
    path: "/my",
    strict: true,
    sensitive: true,
  });

  const border = matchRFCs ? "" : " border-bottom";

  if (matchRFCs) setStatus("RFCs");
  if (matchMy) setStatus("My Drafts");

  return (
    <div
      className={"d-flex m-0 align-items-center sticky-top mainnav" + border}
    >
      {backarrow && (
        <div className="m-3 mr-0" onClick={() => history.goBack()}>
          {BackArrowSVG}
        </div>
      )}
      <Status />
      <div className="pr-2 ml-auto">
        {matchRFCs && 0 ? <ViewModeToggle /> : null}
      </div>
    </div>
  );
}

export { MainNav, useMainNav };
