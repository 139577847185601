import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import RFCComposer from "../composer/RFCComposer";

function RequestForComments() {
  const [show, setShow] = useState(false);
  const showComposer = () => setShow(true);
  const hideComposer = () => setShow(false);

  return (
    <>
      <Button
        variant="primary"
        size="md"
        onClick={showComposer}
        className="d-none d-lg-inline text-base-500"
      >
        Request For Comments
      </Button>
      <Button size="md" onClick={showComposer} className="d-inline d-lg-none">
        <FaPencilAlt size={12} color="white" />
      </Button>
      <RFCComposer show={show} handleClose={hideComposer} />
    </>
  );
}

export default RequestForComments;
