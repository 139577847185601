import React, { useContext } from "react";

const InlineCommentsContext = React.createContext();

export const useInlineComments = () => {
  return useContext(InlineCommentsContext);
};

export function InlineCommentsProvider({ as, setInlineComment, children }) {
  if (as === "RFCHead" || as === "CommentHead")
    return children;

  return (
    <InlineCommentsContext.Provider value={setInlineComment}>
      {children}
    </InlineCommentsContext.Provider>
  );
}
