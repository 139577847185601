import React, { useState } from "react";
import { Button } from "react-bootstrap";
import PublishModal from "./PublishModal";
import DeleteModal from "./DeleteModal";

function Unpublished() {
  const [modal, setModal] = useState("");
  const [show, setShow] = useState(false);
  
  const showModal = (event) => {
    event.stopPropagation();
    setShow(true);
    setModal(event.target.name);
  }

  const hideModal = () => {
    setShow(false);
    setModal("");
  };

  return (
    <>
      <Button
        name="delete"
        variant="secondary"
        size="sm"
        className="align-self-start"
        onClick={showModal}
      >
        Delete
        {modal === "delete" && <DeleteModal show={show} onHide={hideModal} />}
      </Button>
      <Button
        name="publish"
        variant="primary"
        size="sm"
        className="ml-1 align-self-start"
        onClick={showModal}
      >
        Publish
        {modal === "publish" && <PublishModal show={show} onHide={hideModal} />}
      </Button>

      {/** 
      <ButtonDropDown
        button={{ name: "publish", label: "Publish" }}
        menu={menu}
        onClick={handleClick}
      />
      */}
    </>
  );
}

export default Unpublished;
