import { axios } from "../axios";

async function ReadPost(id) {
  try {
    let response = await axios.get(`/posts/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response.data.error;
  }
}

async function SaveRFC(id, title, content) {
  try {
    let response = await axios.post(`/posts/${id}`, {
      title: title,
      content: content,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function CreateComment(id, content) {
  try {
    let response = await axios.post(`/posts/${id}/comments`, {
      content: content,
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function ReadComments(id) {
  try {
    let response = await axios.get(`/posts/${id}/comments`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function DeleteComment(id) {
  try {
    let response = await axios.delete(`/posts/${id}/comments`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

async function ReadCommentsCount(id) {
  try {
    let response = await axios.get(`/posts/${id}/comments/count`);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

const Post = {
  ReadPost,
  SaveRFC,
  CreateComment,
  ReadComments,
  DeleteComment,
  ReadCommentsCount,
};

export default Post;