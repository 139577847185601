import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { usePost } from "../../utils/PostContext";
import { useRFC } from "../../utils/RFCContext";
import RFC from "../../api/RFC";

function DeleteSectionModal({ show, handleClose }) {
  const { post } = usePost();
  const { rfc, dispatchDeleteSection } = useRFC();

  async function handleConfirm() {
    try {
      await RFC.DeleteSectionAndRemove(rfc.id, post.id);
      handleClose();
      dispatchDeleteSection(post);
    } catch (error) {
      console.log(error);
      //handleApiErrors(error, history);
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      onClick={event => event.stopPropagation()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Section</Modal.Title>
      </Modal.Header>
      <Modal.Body>Section will be deleted permanently</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="warning" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function Delete() {
  const { post } = usePost();
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={post.actionbar.delete.icon.className}>
        <FiTrash2
          viewBox={post.actionbar.delete.icon.viewBox}
          strokeWidth={post.actionbar.delete.icon.strokeWidth}
          onClick={event => {
            event.stopPropagation();
            setShow(true);
          }}
        />
      </div>

      <DeleteSectionModal show={show} handleClose={() => setShow(false)} />
    </>
  );
}

export default Delete;
