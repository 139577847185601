import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { Form } from "react-bootstrap";
import RFC from "../../../api/RFC";
import { handleApiErrors } from "../../../common/errors";

// TODO: Form input validation
function RFCComposerForm({
  formId,
  action,
  setIsError,
  setIsSubmitting,
  onHide,
  setHasTitle,
  setContentLength,
}) {
  const history = useHistory();
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  const formCtrlTitle = useRef(null);
  const formCtrlContent = useRef(null);

  function autoresize(element) {
    element.style.height = "auto";
    element.style.height = element.scrollHeight + "px";
    element.scrollTop = element.scrollHeight;
  }

  useEffect(() => {
    formCtrlTitle.current.focus();
    formCtrlContent.current.maxLength = "300";
  }, [title]);

  function handleChangeTitle(event) {
    setIsError(false);
    setTitle(event.target.value); // can't rely on title at this point
    setHasTitle(event.target.value.length);
  }

  function handleChangeContent(event) {
    setIsError(false);
    setContent(event.target.value);
    setContentLength(event.target.value.length); // can't rely on content at this point
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsError(false);
    setIsSubmitting(true);
    try {
      let rfc = await RFC.CreateWithSummary(
        title,
        content,
        action === "publish"
      );
      history.push(`/rfc/${rfc.id}`);
      onHide();
    } catch (error) {
      setIsError(true);
      if (error.statusCode < 500) handleApiErrors(error, history);
    }
    setIsSubmitting(false);
  }

  const contentPlaceHolderText =
    "Summarize your proposal in few words. You can always add more sections later.";
  // "Share what's on your mind, publish and receive feedback ...";
  /*
    "Type a summary of your proposal ...," +
    " you can always add more sections later";
  */
  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <Form.Control
          ref={formCtrlTitle}
          onKeyDown={(e) => e.stopPropagation()} // to prevent losing focus
          className="form-control-title form-control-plaintext"
          as="textarea"
          rows="1"
          placeholder="Title"
          onChange={handleChangeTitle}
          value={title}
        />
        <Form.Control
          ref={formCtrlContent}
          onKeyDown={(e) => e.stopPropagation()} // to prevent losing focus
          className="form-control-content white-space-pre-wrap"
          as="textarea"
          role="textbox"
          rows="4"
          placeholder={contentPlaceHolderText}
          onChange={handleChangeContent}
          onInput={(e) => autoresize(e.target)}
        />{" "}
      </Form>
    </>
  );
}

export default RFCComposerForm;
