import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import RFC from "../../api/RFC/";
import RFCList from "./RFCList";
import { useMainNav } from "../../components/MainNav";
import { handleApiErrors } from "../../common/errors";

function RFCListRenderer() {
  const [list, setList] = useState([]);
  let { listType } = useParams();
  const history = useHistory();
  const { setBackArrow } = useMainNav();
  const [isLoading, setIsLoading] = useState(false);
  const matchMy = useRouteMatch({
    path: "/my",
    strict: true,
    sensitive: true,
  });

  listType = listType || (matchMy ? "myDrafts" : "draft");

  useEffect(() => {
    setBackArrow(false);
    async function fetchRFCList() {
      let rfclist = [];
      setIsLoading(true);
      try {
        if (listType === "myDrafts") {
          rfclist = await RFC.ListMyDraft();
        } else if (listType === "draft") {
          rfclist = await RFC.ListDraft();
        } else if (listType === "active") {
          rfclist = await RFC.ListActive();
        } else if (listType === "archive") {
          rfclist = await RFC.ListArchive();
        }
        setIsLoading(false);
        setList(rfclist);
      } catch (error) {
        handleApiErrors(error, history);
      }
    }
    fetchRFCList();
  }, [listType, setBackArrow, history]);

  if (isLoading) {
    return <Spinner animation="grow" variant="primary" />;
  }

  return (
    <>
      <RFCList list={list} />
    </>
  );
}

export default RFCListRenderer;
