
function setPostHeadProps(post) {
  post.actionbar = {
    comment: {
      icon: {
        className: "mt-2",
        viewBox: "2 2 20 20",
        strokeWidth: "1.5"
      }
    },
  }
}

export default setPostHeadProps;